import React from "react"
// import PageLink from "../components/PageLink"
import styled from "@emotion/styled"
import tw from "twin.macro"
import Layout from "../components/Layout"
//import Container, { Col } from "../components/Container"
import features from "../staticQueries/features"
import FeatureCards from "../components/FeatureCards"

const FeaturesPage: React.FC = () => {
  return (
    <StyledLayout title="Features" page="features">
      <FeatureCards features={features()} />
    </StyledLayout>
  )
}

const StyledLayout = styled(Layout)`
  ${tw``}
`
export default FeaturesPage
